const getBoolean = (value: any, defaultValue: boolean = false): boolean => {
	if (value === undefined) return defaultValue
	if (typeof value === 'string') return value.toLowerCase() === 'true'
	if (typeof value === 'number') return value === 1
	return defaultValue
}

const Config = {
	environment: process.env.ENVIRONMENT,
	operator: process.env.OPERATOR || 'keolis',
	debug: getBoolean(process.env.DEBUG),
	apiUrl: process.env.API_URL || 'https://keolis-api.lijnenkaart.nl/v1',
	apiKey: process.env.API_KEY || '329c927c-7ec1-4cb4-8ed6-51c4f58f7243',
	langUrl: process.env.LANG_URL || 'https://keolis-api.lijnenkaart.nl/lang',
	publicUrl: process.env.PUBLIC_URL || 'https://reizen.keolis.nl',
}

export const isRRReis = Config.operator === 'rrreis'
export const isKeolis = Config.operator === 'keolis'

export default Config
